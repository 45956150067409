<template>
  <div>
    <h1>Ventas por mes</h1>
    <v-row>
      <v-col cols="12" md="2">
        <!-- Selector de años principal -->
        <v-select
          v-model="anio1"
          :items="anios"
          label="Periódo principal"
          outlined
          dense
        ></v-select>
      </v-col>

      <v-col cols="12" md="2">
        <!-- Selector de años para comparar -->
        <v-select
          v-model="anio2"
          :items="anios_pasados"
          label="Comparar con"
          outlined
          dense
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <bar-chart v-if="loaded" :chartdata="ventasPorMes" :options="options" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BarChart from "@/chartjs/bar.chart.vue";
import ReportesAPI from "@/api/reportes";

export default {
  name: "reporte.ventas",
  components: {
    BarChart,
  },
  data() {
    return {
      ventasPorMes: null,
      loaded: false,
      anios: [], // Años actuales hasta los últimos 5
      anios_pasados: [], // Últimos 5 años
      anio1: new Date().getFullYear(), // Año actual
      anio2: new Date().getFullYear() - 1, // Año anterior
      options: {
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
          },
        },

        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (value, index, values) {
                  if (parseInt(value) >= 1000) {
                    return (
                      "$" +
                      value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    );
                  } else {
                    return "$" + value;
                  }
                },
              },

              scaleLabel: {
                display: true,
                labelString: "Ventas",
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0,
              },
              gridLines: {
                drawOnChartArea: false,
                offsetGridLines: true,
                tickMarkLength: 20,
              },
              padding: -15,
              barThickness: 20, // Tamaño de las barras
              categoryPercentage: 0.8, // Ajuste del porcentaje de la categoría
              offset: true, // Asegura el desplazamiento de las barras
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return (
                "$" +
                Number(tooltipItem.yLabel)
                  .toFixed(0)
                  .replace(/./g, function (c, i, a) {
                    return i > 0 && c !== "." && (a.length - i) % 3 === 0
                      ? "," + c
                      : c;
                  })
              );
            },
          },
        },

        animation: {
          duration: 500,
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
      },
    };
  },
  methods: {
    fetchVentasMensual() {
      this.loaded = false;
      ReportesAPI.getReporteVentaMensual(this.anio1, this.anio2).then((response) => {
        this.ventasPorMes = response.data;
        this.anios = response.data.anios; // Guardar los años actuales
        this.anios_pasados = response.data.anios_pasados; // Guardar los últimos 5 años
        this.loaded = true;
      });
    },
  },
  watch: {
    anio1() {
      this.fetchVentasMensual(); // Actualizar reporte al cambiar el año principal
    },
    anio2() {
      this.fetchVentasMensual(); // Actualizar reporte al cambiar el año de comparación
    },
  },
  mounted() {
    this.fetchVentasMensual(); // Cargar el reporte al montar el componente
  },
};
</script>
