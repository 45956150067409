<template>
  <v-row justify="start" align="end">
    <v-col v-for="(billete, i) in billetes" :key="i" cols="6" md="4" lg="4">
      <v-card flat>
        <div class="text-center" style="font-size: 15px">
          <b>{{ billete.valorBillete | convertPesoArg }}</b>
        </div>
        <div class="text-center d-flex justify-center align-center minusplusnumber">
          <div class="mpbtn minus" @click="quitBillete(billete)">
            <v-icon>mdi-minus</v-icon>
          </div>
          <div id="field_container">
            <input
              style="color=white"
              type="number"
              v-model="billete.cantidad"
              @input="sendMonto"
              onFocus="this.select()"
            />
          </div>
          <div class="mpbtn plus" @click="addBillete(billete)">
            <v-icon>mdi-plus</v-icon>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "contar.billetes",
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  props: {
    billetes: {
      type: Array,
      required: false,
      default: function () {
        return [
          { valorBillete: "20000", cantidad: null },
          { valorBillete: "10000", cantidad: null },
          { valorBillete: "2000", cantidad: null },
          { valorBillete: "1000", cantidad: null },
          { valorBillete: "500", cantidad: null },
          { valorBillete: "200", cantidad: null },
          { valorBillete: "100", cantidad: null },
          { valorBillete: "50", cantidad: null },
          { valorBillete: "20", cantidad: null },
          { valorBillete: "10", cantidad: null },
          { valorBillete: "5", cantidad: null },
        ];
      },
    },
    textButton: {
      type: String,
      required: true,
      default: "defaultIngresar",
    },
    cols: {
      type: String,
      required: false,
      default: "4",
    },
  },
  data() {
    return {};
  },
  computed: {
    monto() {
      var saldo = 0;
      this.billetes.forEach((element) => {
        if (element.cantidad !== null) {
          saldo += element.cantidad * parseInt(element.valorBillete);
        }
      });
      return saldo;
    },
  },
  methods: {
    sendMonto() {
      this.$emit("process-monto", this.billetes);
    },
    addBillete(billete) {
      var cantidad = parseInt(billete.cantidad);
      if (cantidad) {
        billete.cantidad = cantidad + 1;
      } else {
        billete.cantidad = 1;
      }

      this.sendMonto();
    },
    quitBillete(billete) {
      var cantidad = parseInt(billete.cantidad);
      if (cantidad == 0) {
        return;
      }
      billete.cantidad = cantidad -= 1;
      this.sendMonto();
    },
  },
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.minusplusnumber {
  border-radius: 5px;
  margin: 0 5px 0 5px;
  display: inline-block;
  user-select: none;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  color: white;
}
.minusplusnumber div {
  display: inline-block;
}
.minusplusnumber #field_container input {
  width: 60px;
  height: 40px;
  text-align: center;
  font-size: 15px;
  padding: 3px;
  margin-bottom: 5px;
  border: none;
  color: #fff;
  border-radius: 5px;

  background-color: #303030;
}
.minusplusnumber .mpbtn {
  padding: 3px 10px 3px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-inline: 4px 4px;
}
.minusplusnumber .mpbtn:hover {
  background-color: #303030;
}
.minusplusnumber .mpbtn:active {
  background-color: #303030;
}
</style>
