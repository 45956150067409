<template>
  <LayoutBasic :showLinksMenu="false" :routeBack="'/ventas'">
    <template v-slot:breadcrumbs>
      <v-breadcrumbs class="pa-3 ml-1" :items="navigation" divider=">">
        <v-breadcrumbs-item
          slot="item"
          slot-scope="{ item }"
          exact
          :to="item.to"
          >{{ item.text }}</v-breadcrumbs-item
        >
      </v-breadcrumbs>
    </template>
    <template v-slot:navigation-title> Consultar ventas </template>
    <template v-slot:navigation-content>
      <v-container>
        <v-row>
          <v-col>
            <div style="font-size: 14px">
              Ingresa los últimos números sin los ceros de la izquierda
            </div>
            <VentasBuscador
              class="mt-2"
              :numeroComprobante="numeroComprobante"
              @update:numeroComprobante="setNroComprobante"
            ></VentasBuscador>
          </v-col>
          <v-col cols="12" lg="12">
            <cliente-buscador @set-cliente="setCliente" @remove-cliente="removeCliente"></cliente-buscador>
          </v-col>

          <v-col cols="12" sm="12" md="6">
            <v-menu
              v-model="menu2"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="fechaInicio"
                  label="Fecha desde"
                  prepend-icon="mdi-calendar"
                  readonly
                  color="green lighten-1"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="green lighten-1"
                v-model="fechaInicio"
                no-title
                :max="moment().format('YYYY-MM-DD')"
                locale="es"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-menu
              v-model="menu"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="fechaFin"
                  prepend-icon="mdi-calendar"
                  label="Fecha hasta"
                  readonly
                  color="green lighten-1"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="green lighten-1"
                v-model="fechaFin"
                no-title
                :max="moment().format('YYYY-MM-DD')"
                :min="fechaInicio"
                locale="es"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12">
            <v-btn large color="info" block @click="fetchVentas"
              >Consultar</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:vista-previa>
      <v-container v-if="loading">
        <v-row v-for="i in 3" :key="i">
          <v-col cols="12">
            <v-skeleton-loader
              max-height="350"
              type="list-item-avatar-three-line"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-list-item
          >{{ moment(fechaInicio).format("LL") }} al
          {{ moment(fechaFin).format("LL") }}</v-list-item
        >
        <!-- <remito-consultar-lista
          :paginacion="paginacion"
          @set-page="setPage"
          ref="consultarPedidos"
          :remitos="remitos"
          @abrir-vista-previa=""
        ></remito-consultar-lista> -->
        <VentaVistaPreviaDrawer
          v-if="drawerVistaPrevia"
          :model="drawerVistaPrevia"
          @close-dialog="drawerVistaPrevia = false"
          @set-drawer="setDrawer"
          @refresh:venta="setVentaUpdated"
          :venta="ventaSelected"
        ></VentaVistaPreviaDrawer>
        <FacturaVentaList
          :facturas="remitos"
          @set-page="setPage"
          :paginacion="paginacion"
          @abrir-vista-previa="setRemito"
        ></FacturaVentaList>
      </v-container>
    </template>
  </LayoutBasic>
</template>

<script>
import VentasBuscador from "@/components/venta/consultar/VentasBuscador.vue";
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import FacturaCompraList from "@/components/compra/factura/FacturaCompraList.vue";

import VentaVistaPreviaDrawer from "@/components/venta/listar/VentaVistaPreviaDrawer.vue";
import ClienteBuscador from "@/components/cliente/cliente.buscador.vue";
import FacturaVentaList from "@/components/venta/factura/FacturaVentaList.vue";

export default {
  name: "VentaConsultar",
  components: {
    FacturaCompraList,
    LayoutBasic,
    VentasBuscador,
    VentaVistaPreviaDrawer,
    ClienteBuscador,
    FacturaVentaList,
  },

  data() {
    return {
      remitoSeleccionado: null,
      paginacion: { page: 1, cantidad: 0, rowsPerPage: 10 },
      drawerVistaPrevia: false,
      remitos: [],
      loading: false,
      estados: ["Pagado", "Pendiente De Pago"],
      estado: null,
      dates: [new Date().toISOString().substr(0, 10)],
      menu2: false,
      menu: false,
      fechaInicio: new Date().toISOString().substr(0, 10),
      fechaFin: new Date().toISOString().substr(0, 10),
      cliente: null,
      sucursal_id: 0,
      numeroComprobante: "", // Nuevo: almacena el número ingresado en el buscador venta

      item: null,
      items: [
        {
          text: "Nuevo pedido",
          icon: "mdi-clock",
          route: "nuevoPedidoCompra",
        },
        {
          text: "Consultar",
          icon: "mdi-account",
          route: "pedidosCompraConsultar",
        },
        { text: "Historial", icon: "mdi-flag", route: "#" },
      ],
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },

    navigation() {
      return [
        {
          text: "Ventas",
          disabled: false,
          to: { name: "ventas" },
        },
      
        {
          text: "Consultar",
          disabled: false,
          to: { name: "consultarVenta" },
        },
      ];
    },
  },
  methods: {
    setNroComprobante(val) {
      this.numeroComprobante = val;
    },
    setDrawer(val) {
      this.drawerVistaPrevia = val;
    },
    setRemito(venta) {
      this.drawerVistaPrevia = true;
      this.ventaSelected = venta;
    },
    removeCliente(){
        this.cliente = null
    },
    setCliente(data) {
      this.cliente = data;
      this.fetchVentas();
    },
    setPedidosfromBuscador(data) {
      this.remitos = data.results;
      this.paginacion.cantidad = data.count;
    },
    fetchVentas() {
      console.log(this.numeroComprobante);

      // Activa el estado de carga
      this.loading = true;

      // Construye la URL base con los parámetros comunes
      let url = `/comprobantes/comprobantes?esVenta=true&doc=FC|NV&page=${this.paginacion.page}&page_size=${this.paginacion.rowsPerPage}&fecha_contable_after=${this.fechaInicio}&fecha_contable_before=${this.fechaFin}`;

      // Agrega el filtro de cliente si existe
      if (this.cliente) {
        url += `&cliente=${this.cliente.id}`;
      }

      // Agrega el filtro por número de comprobante si se ingresó
      if (this.numeroComprobante) {
        url += `&numero=${this.numeroComprobante}`;
      }

      // Realiza la petición
      this.axios.get(url).then((response) => {
        this.remitos = response.data.results;
        this.paginacion.cantidad = response.data.count;
        this.loading = false;
      });
    },
    setPage(page) {
      this.loading = true;
      this.paginacion.page = page;
      this.fetchVentas();
    },
  },
  created() {
    this.fetchVentas();
  },
};
</script>

<style lang="scss" scoped></style>
