<template>
  <v-card color="transparent" flat class="items3">
    <v-snackbar color="error" v-model="error_detalle_repetido.state" multi-line>
      {{ error_detalle_repetido.message }}</v-snackbar
    >

    <v-list color="transparent" class="detalle" dense>
      <v-subheader>Detalles del pedidsso</v-subheader>

      <v-list-item v-for="(detalle, index) in detalles" :key="index">
        <v-list-item-icon>{{ detalle.cantidad }}x</v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{
            detalle.descripcion_concepto
          }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon class="align-center">
  <div style="font-size: 20px">
    {{
      Number((detalle.precioVenta ? detalle.precioVenta : detalle.precioUnitario) * detalle.cantidad) | convertPesoArg
    }}
  </div>
</v-list-item-icon>


        <v-list-item-icon class="align-center">
          <pedido-detalle-buttons
            v-if="!disabledDetalle"
            :detalle="detalle"
            @process-increment="incrementCant(detalle)"
            @process-decrement="decrementCant(detalle)"
            @process-delete="eliminarDetalle(detalle)"
          ></pedido-detalle-buttons>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <v-dialog
      v-if="dialog_detalle && !disabledDetalle"
      v-model="dialog_detalle"
      width="500"
    >
      <detalle-pedido-form
        @close-dialog="dialog_detalle = false"
        @process-detalle="modificarDetalle"
        :detalle="detalle_update"
      ></detalle-pedido-form>
    </v-dialog>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
import {
  quit_detalle_update,
  update_detalle_update,
} from "@/services/detallesComprobante/serviceDetallesUpdate";
import { mapState } from "vuex";
import DetallePedidoForm from "@/components/pedido/DetallePedidoForm";
import ItemDetallePedido from "@/components/pedido/ItemDetallePedido";
import PedidoDetalleButtons from "../pedido.detalle.buttons.vue";

export default {
  name: "pedido.detalle.actualizar",
  components: {
    DetallePedidoForm,
    ItemDetallePedido,
    PedidoDetalleButtons,
  },
  props: {
    detalles: {
      type: Array,
      required: true,
    },
    disabledDetalle: {
      type: Boolean,
      default: true,
    },
    esVenta: {
      type: Boolean,
      default: true,
    },
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  data() {
    return {
      dialog_detalle: false,
      detalle: null,
      detalle_update: {},
    };
  },
  computed: {
    ...mapState("pedidos", ["error_detalle_repetido"]),
    ...mapState("sucursales", ["configuracion"]),
  },
  methods: {
    incrementCant(detalle) {
      detalle.cantidad = parseFloat(detalle.cantidad);
      console.log(detalle.cantidad);
      console.log(detalle);

      const isPizza = detalle.producto?.categoriaArbol?.name.includes("PIZZA");
      const canControlStock =
        this.esVenta &&
        this.configuracion.productoInventario &&
        detalle.producto?.controlaStock;
      const hasStock = detalle.producto?.cantidadstock > detalle.cantidad;

      if (canControlStock && hasStock) {
        // Incrementa cantidad según tipo de producto cuando hay control de stock y stock disponible
        detalle.cantidad += isPizza ? 0.5 : 1;
      } else if (!this.esVenta || !canControlStock || !hasStock) {
        // Incrementa sin control de stock (para compras o cuando no hay control de stock)
        detalle.cantidad += isPizza ? 0.5 : 1;
      }

      this.modificarDetalle(detalle);
    },
    decrementCant(detalle) {
      detalle.cantidad = parseFloat(detalle.cantidad);
      if (detalle.producto) {
        if (detalle.producto.categoriaArbol.name.includes("PIZZA")) {
          if (detalle.cantidad == 0.5) {
            return;
          }
          detalle.cantidad = parseFloat(detalle.cantidad) - 0.5;
        } else {
          if (detalle.cantidad == 1) {
            return;
          }

          detalle.cantidad--;
        }
      } else {
        if (detalle.cantidad == 1) {
          return;
        }

        detalle.cantidad--;
      }
      this.modificarDetalle(detalle);
    },
    eliminarDetalle(detalle) {
      var detalle_pedido = {};

      if (detalle.producto) {
        detalle_pedido = {
          id: detalle.id,
          producto: {
            id: detalle.producto.id,
            descripcion: detalle.producto.descripcion,
          },
          cantidad: detalle.cantidad,
        };
      } else {
        detalle_pedido = {
          id: detalle.id,
          promocion: {
            id: detalle.promocion.id,
            descripcion: detalle.promocion.descripcion,
          },
          cantidad: detalle.cantidad,
        };
      }
      let detalle_send = { ...detalle_pedido };
      quit_detalle_update(this.detalles, detalle_send);
    },
    modificarDetalle(detalle) {
      var detalle_pedido = {};
      this.dialog_detalle = false;
      if (detalle.producto) {
        detalle_pedido = {
          id: detalle.id,
          producto: {
            id: detalle.producto.id,
            descripcion: detalle.producto.descripcion,
          },
          cantidad: detalle.cantidad,
        };
      } else {
        detalle_pedido = {
          id: detalle.id,
          promocion: {
            id: detalle.promocion.id,
            descripcion: detalle.promocion.descripcion,
          },
          cantidad: detalle.cantidad,
        };
      }
      let detalle_send = { ...detalle_pedido };

      update_detalle_update(this.detalles, detalle_send);
    },

    // eliminarDetalle(detalle) {
    //   this.$emit("eliminarDetalle", detalle);
    // },
    // modificarDetalle(detalle){
    //   this.dialog_detalle = false
    //   this.$emit("update-detalle", detalle);

    // },
    setDetalle(detalle) {
      let detalle_pedido = { ...detalle };
      this.detalle_update = detalle_pedido;
      this.dialog_detalle = true;
    },
  },
  watch: {
    dialog_detalle: function (val) {
      if (val == false) (this.detalle = null), (this.detalle_update = {});
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      //Si el dialog_detalle es false, y encontro un producto, levanta el dialogo para agregar la cantidad
      event.preventDefault();

      // +
      if (event.keyCode === 107) {
        this.detalle = null;
      }
      //escape
      if (event.keyCode === 27) {
        this.detalle = null;
      }
      //suprimir
      //Elimina el detalle si esta seleccionado y el dialog para agregar detalle esta desactivado
      if (event.keyCode === 46 && this.dialog_detalle == false) {
        if (this.detalle !== null) {
          event.preventDefault();
          this.eliminarDetalleUpdate(this.detalles[this.detalle]);
        }
        this.detalle = null;
      }

      //Flecha hacia abajo
      if (event.keyCode === 40 && this.dialog_detalle == false) {
        if (this.detalles.length == 0) return;

        if (this.detalle == null) {
          this.detalle = 0;
        } else if (this.detalle == this.detalles.length - 1) {
          this.detalle = 0;
        } else {
          this.detalle = this.detalle + 1;
        }
      }

      //Flecha hacia arriba
      if (event.keyCode === 38 && this.dialog_detalle == false) {
        if (this.detalles.length == 0) return;

        if (this.detalle == null) {
          this.detalle = this.detalles.length - 1;
        } else if (this.detalle == 0) {
          this.detalle = this.detalles.length - 1;
        } else {
          this.detalle = this.detalle - 1;
        }
      }

      //ENTER - Abre el modal cuando esta seleccionado algun elemento de la lista (detalle.length > 0)
      if (event.keyCode === 13) {
        if (this.detalles.length > 0) {
          if (this.detalle !== null && this.dialog_detalle == false) {
            this.setDetalle(this.detalles[this.detalle]);
          }
        }
      }
    });
  },
};
</script>

<style scoped>
.v-list.detalle {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.items3:hover ::-webkit-scrollbar {
  width: 8px !important;
}
.items3 ::-webkit-scrollbar {
  width: 8px;
}
/* width */

/* Track */
.items3 ::-webkit-scrollbar-track {
  background: transparent;
}
.items3 ::-webkit-scrollbar-thumb:hover {
  background: rgb(223, 6, 6);
}
/* Handle */
.items3 ::-webkit-scrollbar-thumb {
  background: #888;
}
</style>
