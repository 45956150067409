<template>
  <LayoutBasic :showLinksMenu="false">
    <!-- <template v-slot:breadcrumbs>
        <v-breadcrumbs class="pa-0 pl-5":items="navigation" divider=">">
          <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">{{ item.text }}</v-breadcrumbs-item>
        </v-breadcrumbs>
      </template> -->
    <template v-slot:navigation-title> Cajas </template>

    <template v-slot:vista-previa>
      <v-row>
        <v-col>
          <v-list color="transparent" two-line>
            <v-list-item @click="goToSucursales">
              <v-list-item-avatar>
                <v-icon>mdi-bank</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ sucursal.nombre }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ sucursal.direccion }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </template>
    <template v-slot:vista-previa-title>
      <div class="title">Listado de cajas</div>
    </template>
    <template v-slot:vista-previa-action>
      <CajaRegistrar
        v-if="user.role.includes('admin')"
        @process-fetch="fetchCajas"
      ></CajaRegistrar>
    </template>
    <template v-slot:vista-previa-list>
      <v-alert type="warning" v-if="error_fecth_message">
        No se pudo obtener las cajas de la sucursal.</v-alert
      >
      <v-skeleton-loader
        v-for="i in 4"
        :key="i"
        v-show="loading"
        max-height="350"
        type="list-item-avatar"
      ></v-skeleton-loader>
      <v-list v-if="!loading">
    <v-list-item
      v-for="(item, index) in cajas"
      :key="index"
      :disabled="isDisabled(item)"
      @click="isClickable(item)"
    >
      <v-list-item-avatar>
        <v-icon dark> mdi-desktop-classic </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title> {{ item.nombre }}</v-list-item-title>
        <v-list-item-subtitle v-if="item.ocupada">
          <span v-if="item.user === user.username"> Esta es tu caja. </span>
          <span v-else> Ocupada por {{ item.user }} </span>
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          Caja disponible.
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon :disabled="isDisabled(item)">
              <v-icon v-on="on">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              dense
              v-for="(option, optionIndex) in menuOptions"
              :key="optionIndex"
              @click="handleOptionSelected(item, option)"
            >
              <v-list-item-icon v-if="option.icon">
                <v-icon>{{ option.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ option.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>
    </v-list-item>
  </v-list>
      <!-- <GenericList
        :menuOptions="menuOptions"
        :items="cajas"
        attributeToShow="nombre"
        @option-selected="handleOptionSelected"
        @item-selected="handleItemSelected"
      >
       <template v-slot:subtitle>
        <div> Ocupada</div>
       </template>
      </GenericList> -->
    </template>
  </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import { getStorage, saveStorage } from "@/services/serviceLocalStorage";
import CajaRegistrar from "@/components/sucursal/caja/CajaRegistrar.vue";
import GenericList from "../../components/util/GenericList.vue";
import { mapState } from "vuex";

export default {
  name: "Cajas",
  $_veeValidate: {
    validator: "new",
  },
  components: {
    LayoutBasic,
    CajaRegistrar,
    GenericList,
  },
  data() {
    return {
      tab: null,
      caja: { descripcion: "" },
      loading: false,
      cajaSeleccionada: null,
      dialog: false,

      localidad: null,
      cajas: [],
      error_fecth_message: null,
      sucursalSeleccionada: null,
      menuOptions: [{ icon: null, title: "Configurar la caja" }],
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    sucursalUuid() {
      return this.$store.getters["sucursales/sucursalUuid"];
    },
    sucursal() {
      return this.$store.state.sucursales.sucursal;
    },
  },
  methods: {
    isDisabled(item) {
      // Si el usuario tiene una caja ocupada, deshabilitar todas las demás cajas
      const userHasOccupiedBox = this.cajas.some(caja => caja.ocupada && caja.user === this.user.username);
      
      if (userHasOccupiedBox) {
        return item.user !== this.user.username;
      }

      // Si la caja está ocupada, deshabilitarla
      return item.ocupada;
    },

    isClickable(item) {
      console.log(item);
      console.log(this.user.role);
      console.log( this.user.role.includes("admin") ||
      this.user.role.includes("owner"));
      
      if (
        this.user.role.includes("admin") ||
        this.user.role.includes("owner")
      ) {
        console.log("Holi");
        this.handleItemSelected(item)
      } else {
        console.log("Holi 2");

        if (item.ocupada) {
          return;
        }
        this.handleItemSelected(item)

      }
    },
    handleItemSelected(caja) {
      console.log("handleItemSelected", caja);
      this.$store.commit("sucursales/SET_CAJA", caja);
      this.$store.commit("sucursales/SET_CAJA_ID_COOKIE", caja.id);

      const url = `/sucursales/arqueos/${caja.id}`;

      this.axios
        .get(url)
        .then((response) => {
          if (response.data.vigente) {
            store.commit("sucursales/SET_ARQUEO_CAJA", response.data.vigente);
            this.$router.push("/home");
          } else {
            this.$router.push("/iniciarCaja");
          }
        })
        .catch((error) => {
          console.error("Error al obtener arqueos:", error);
          next();
        });
    },
    handleOptionSelected(item, option) {
      this.$router.push({
        name: "cajaSettings",
        params: { id_caja: item.id },
      });
    },
    goToSucursales() {
      this.$router.push("/sucursales");
    },
    async fetchCajas() {
      try {
        this.loading = true;
        var url = `/sucursales/cajas`;
        const response = await this.axios.get(url);
        this.cajas = response.data;
      } catch (error) {
        this.loading = false;
        this.error_fecth_message = error.response.data;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetchCajas();
  },
};
</script>

<style scoped>
.rounded-card {
  border-radius: 50px;
}
.two-colors {
  background: #005d5b;
  background: linear-gradient(
    180deg,
    #005d5b 0,
    #005d5b 200px,
    hsla(0, 0%, 97.6%, 0) 0,
    hsla(0, 0%, 97.6%, 0) 0
  );

  height: 150vh;
}
</style>
