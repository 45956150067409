<template>
  <div>
    <!-- Campo para ingresar el precio de venta manual -->
    <v-text-field
      color="green"
      class="title"
      :prefix="producto.precioVenta ? '$' : ''"
      id="precioVenta"
      v-model="precioVenta"
      :label="precioVentaLabel"
      v-validate="
        `greater_than_cost:${producto.precioCosto}|required|decimal:2|max:30`
      "
      :error-messages="errors.collect('precioVenta')"
      data-vv-name="precioVenta"
      data-vv-as="El Precio de venta"

      type="number"
      :outlined="outlined"
      :disabled="disabled"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: "form.producto.precio.venta",
  inject: ["$validator"],

  props: {
    producto: {
      type: Object,
      required: true, // Objeto que contiene los datos del producto
    },
    esPrecioVentaManual: {
      type: Boolean,
      required: true,
      default: true, // Objeto que contiene los datos del producto
    },
    outlined: {
      type: Boolean,
      default: false, // Define si el campo tiene un borde
    },
    disabled: {
      type: Boolean,
      default: false, // Define si el campo está deshabilitado
    },
    tipoRedondeo: {
      type: String,
      default: "entero", // Tipo de redondeo: "entero" o "decimales"
      validator: (value) => ["decimales", "entero"].includes(value),
    },
  },

  computed: {
    /**
     * Etiqueta dinámica del campo de precio de venta
     */
    precioVentaLabel() {
      return this.tipoRedondeo === "entero"
        ? "Precio de venta manual"
        : "Precio de venta calculado";
    },
    precioVenta: {
      get() {
        console.log(this.esPrecioVentaManual);

        if (this.esPrecioVentaManual) {
          return this.producto.precioVenta;
        }
        if (
          this.producto.precioCosto > 0 &&
          this.producto.ganancia > 0 &&
          this.producto.flete >= 0 &&
          this.producto.iva
        ) {
          const flete = this.producto.flete
            ? parseFloat(this.producto.flete) / 100
            : 0;
          const ivaVenta = parseFloat(this.producto.iva.description) / 100;
          const ganancia = parseFloat(this.producto.ganancia) / 100;

          // Calcula el precio de venta redondeado al entero más cercano
          const subtotal =
            this.producto.precioCosto *
            (1 + ganancia) *
            (1 + ivaVenta) *
            (1 + flete);
          var precioVenta = parseFloat(subtotal.toFixed(2));

          return precioVenta;
        }

        return null;
      },
      set(val) {
        // Actualiza el valor en producto.precioVenta cuando se asigna un nuevo valor
        this.producto.precioVenta = val;
      },
    },

    /**
     * Calcula automáticamente la ganancia en porcentaje
     */
   
  },

  methods: {
    calcularGanancia() {
      const costoTotal = this.calcularCostoTotal();

      // Validar que ambos valores existan y sean mayores a cero
      if (costoTotal > 0 && this.producto.precioVenta > 0) {
        // Eliminar IVA del precio de venta para obtener el precio base
        const ivaVenta = this.producto.iva
          ? parseFloat(this.producto.iva.description) / 100
          : 0;

        const precioVentaSinIVA = this.producto.precioVenta / (1 + ivaVenta);

        // Calcular el margen de ganancia en porcentaje
        const ganancia = (precioVentaSinIVA / costoTotal - 1) * 100;

        this.producto.ganancia = parseFloat(ganancia.toFixed(2));
        return parseFloat(ganancia.toFixed(2));
      }
      return 0; // Si no se puede calcular, retorna 0
    },
    /**
     * Calcula el costo total del producto (precio costo + flete + otros costos)
     */
    calcularCostoTotal() {
      const precioCosto = this.producto.precioCosto || 0;
      const flete = this.producto.flete
        ? parseFloat(this.producto.flete) / 100
        : 0;

      // Costo ajustado con el flete
      return precioCosto * (1 + flete);
    },
  },
  watch: {
    "producto.precioVenta": {
      handler(val) {
        if (val != null) {
          let ganancia = this.calcularGanancia();
          this.producto.ganancia = Math.round(ganancia * 100) / 100;
        }
      },
    },
  },
};
</script>

<style scoped>
/* Estilos específicos para este componente */
.title {
  margin-bottom: 16px;
}

p {
  font-size: 14px;
  color: #555;
}
</style>
