<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="facturas"
      class="row-pointer transparent"
      no-data-text="No se encontraron resultados"
      @click:row="handleClick"
      hide-default-footer
    >
      <!-- <template v-slot:item.fechaAlta="{ item }">
          {{ moment(item.fechaAlta).startOf("minutes").fromNow() }}
        </template> -->
      <template v-slot:item.fechaAlta="{ item }">
        {{ moment(item.fechaAlta).format("ll") }}
      </template>
      <template v-slot:item.fecha_contable="{ item }">
        {{ moment(item.fecha_contable).format("ll") }}
      </template>
      <template v-slot:item.origen="{ item }">
        <v-icon v-if="!pendienteFacturacion && item.origen"
          >mdi-cloud-question</v-icon
        >

        <v-icon v-if="!pendienteFacturacion && !item.origen" color="green"
          >mdi-cloud-check-outline</v-icon
        >
        <v-icon v-if="pendienteFacturacion && item.origen" color="orange"
          >mdi-cloud-alert</v-icon
        >
      </template>
      <template v-slot:item.total="{ item }"
        >{{ item.total | convertPesoArg }}
      </template>
    </v-data-table>
    <v-row>
      <v-col>
        <v-pagination
          v-if="facturas"
          @input="paginationChangeHandler"
          :length="pages"
          :value="paginacion.page"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>
  
  <script>
import { mapState, mapActions } from "vuex";

import DetallePedidoForm from "@/components/pedido/DetallePedidoForm";

export default {
  name: "FacturaVentaList",
  components: {
    DetallePedidoForm,
  },
  props: {
    facturas: {
      type: Array,
      required: true,
    },
    paginacion: {
      type: Object,
      required: true,
    },
    pendienteFacturacion: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        { text: "Fecha Creación", value: "fechaAlta", sortable: false },
        { text: "Fecha Contable", value: "fecha_contable", sortable: false },

        // { text: "Creacion", value: "fechaAlta", sortable: false },

        { text: "Nº", value: "numero_comprobante", sortable: false },
        { text: "Total", value: "total", sortable: false },

        { text: "Cliente", value: "cliente.nombre", sortable: false },
        { text: "Factura", value: "origen", sortable: false },
      ],
    };
  },

  computed: {
    pages() {
      if (this.paginacion.rowsPerPage == null || this.paginacion.cantidad < 0) {
        return 0;
      }

      return Math.ceil(this.paginacion.cantidad / this.paginacion.rowsPerPage);
    },
  },
  methods: {
    paginationChangeHandler(pageNumber) {
      if (this.paginacion.page == pageNumber) {
        return false;
      }
      this.$emit("set-page", pageNumber);
    },
    handleClick(pedido) {
      this.$emit("abrir-vista-previa", pedido);
    },
    async abrirPedido(pedido) {
      this.$router.push({
        name: "ActualizarPedido",
      });
    },
  },
  created() {},
};
</script>
  
  <style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
  