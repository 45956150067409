<template>
  <v-card>
    <v-card-title
      ><h2>Pagar comprobante</h2>

      <v-spacer></v-spacer>
      <v-btn large icon @click="$emit('close-dialog-cobrar')"
        ><v-icon>mdi-close</v-icon></v-btn
      ></v-card-title
    >
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              >Comprobante: {{ comprobante.codigo }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              >Proveedor: {{ comprobante.proveedor.nombre }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
    </v-card-text>
    <v-container>
      <FormaDePagoList
        :formasDePago="formas_de_pago_para_cobro"
        @total-cambiado="actualizarTotal"
      ></FormaDePagoList>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title><h2>Total</h2></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon
          ><h2>{{ Number(comprobante.saldo) | convertPesoArg }}</h2>
        </v-list-item-icon>
      </v-list-item>

      <v-list v-if="estaDebiendo">
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>
              <div>
                <h2 class="grey--text">Faltan:</h2>
              </div></v-list-item-title
            >
          </v-list-item-content>

          <v-list-item-icon>
            <h2 class="grey--text">{{ faltante() | convertPesoArg }}</h2>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-alert border="top" colored-border type="info" elevation="2">
              <div class="">
                El faltante de {{ faltante() | convertPesoArg }} quedará en la
                cuenta corriente del proveedor.
              </div>
            </v-alert>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list v-if="estaSobrando">
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>
              <div>
                <h2 class="orange--text">Sobrante:</h2>
              </div></v-list-item-title
            >
          </v-list-item-content>

          <v-list-item-icon>
            <h2 class="orange--text">{{ faltante() | convertPesoArg }}</h2>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-alert border="top" colored-border type="warning" elevation="2">
              <div class="">No puedes pagar de más.</div>
            </v-alert>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <v-btn
              @click="pagarComprobante"
              :loading="loadingButtonPagar"
              color="success"
              :disabled="total <= 0 || vuelto() > 0"
              block
              >Pagar</v-btn
            >
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-container>
  </v-card>
</template>

<script>
import FormaDePagoList from "@/components/comprobante/formadepago/FormasDePagoList.vue";
import FACTURA_COMPRA_API from "@/api/compra/factura.js";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "ComprobanteCompraPagar",
  components: {
    FormaDePagoList,
  },
  props: {
    comprobante: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dejar_vuelto_en_cuenta: false,
      loadingButtonPagar: false,
      montosPorFormaDePago: [], // Utilizamos un array para almacenar los montos de las formas de pago
    };
  },
  computed: {
    ...mapState("comprobantes", ["formas_de_pago_para_cobro"]),
    // Calculamos el total sumando los montos de todas las formas de pago
    total() {
      // Sumamos los montos de todas las formas de pago
      return this.montosPorFormaDePago.reduce(
        (acc, curr) => acc + (curr.monto || 0),
        0
      );
    },

    estaPagando() {
      return Math.round(this.total) > 0;
    },
    estaDebiendo() {
      return this.estaPagando && this.comprobante.saldo > this.total;
    },
    estaSobrando() {
      return this.estaPagando && this.total > this.comprobante.saldo;
    },
  },
  methods: {
    ...mapActions("comprobantes", ["fetch_formas_de_pago_para_cobro"]),
    actualizarTotal(payload) {
      // Buscamos si ya hay un monto registrado para esta forma de pago
      const index = this.montosPorFormaDePago.findIndex(
        (item) => item.formaDePago === payload.formaDePago.nombre
      );
      if (index !== -1) {
        // Si encontramos un monto registrado, lo actualizamos
        this.$set(this.montosPorFormaDePago, index, {
          formaDePago: payload.formaDePago.nombre,
          monto: payload.monto,
        });
      } else {
        // Si no encontramos un monto registrado, lo agregamos
        this.montosPorFormaDePago.push({
          formaDePago: payload.formaDePago.nombre,
          monto: payload.monto,
        });
      }
    },
    vuelto() {
      return (this.total - this.comprobante.saldo).toFixed(2);
    },
    faltante() {
      return Math.abs(this.total - this.comprobante.saldo).toFixed(2);

    },
    pagarComprobante() {
      this.loadingButtonPagar = true;
      var data = {
        comprobante_id: this.comprobante.id,
        total: this.total,
        formas_de_pago: this.montosPorFormaDePago,
      };
      FACTURA_COMPRA_API.payment(data)
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Pago registrado",
            icon: "mdi-check",
            timeout: 3000,
          });
          this.$emit("success-payment");
          this.loadingButtonPagar = false;
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "error",
            text: `${e.response.data}`,
            icon: "mdi-information",
            timeout: 3000,
          });
          this.$emit("close-dialog-cobrar");
          this.loadingButtonPagar = false;
        });
    },
  },
  created() {
    this.fetch_formas_de_pago_para_cobro();
  },
};
</script>

<style lang="scss" scoped>
</style>