var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"items":_vm.clientes,"search-input":_vm.inputSearch,"disabled":false,"outlined":"","label":"Buscar cliente","append-icon":"mdi-magnify","item-text":_vm.getItemText,"loading":_vm.isLoading,"return-object":"","clearable":"","autofocus":""},on:{"update:searchInput":function($event){_vm.inputSearch=$event},"update:search-input":function($event){_vm.inputSearch=$event},"click:clear":_vm.removeCliente},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.showIconAppend)?_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){}}},on),[_c('v-icon',[_vm._v(" mdi-account-plus-outline ")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Agregar cliente")])])]},proxy:true},{key:"no-data",fn:function(){return [_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-title',[_vm._v(" No se encontraron resultados. ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"blue-grey"}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account ")]),_c('span',{domProps:{"textContent":_vm._s(item.nombre)}}),_c('span',{domProps:{"textContent":_vm._s(item.apellido)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.nombre)+" "+_vm._s(item.apellido))]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.documento)}})],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-account")])],1)]}}]),model:{value:(_vm.clienteLocal),callback:function ($$v) {_vm.clienteLocal=$$v},expression:"clienteLocal"}})}
var staticRenderFns = []

export { render, staticRenderFns }