<template>
  <v-card >
    <v-container>
      
    
      <v-row>
        <v-col cols="12">
          <v-toolbar color="transparent" flat>
            <v-icon
              class="mr-1"
              large
              v-show="esIngreso"
              @click="$emit('close-dialog')"
              v-text="esIngreso ? 'mdi-cash-plus' : 'mdi-arrow-left'"
            ></v-icon>
            <v-btn v-show="!esIngreso" icon @click="$emit('close-dialog')">
              <v-icon large>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>
              <h2 class="mb-3 mt-2 text-left text-white">
                {{ esIngreso ? "Ingreso " : "Retiro " }} de dinero
              </h2>
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn v-show="esIngreso" icon @click="$emit('close-dialog')"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>

          <v-row class="mt-2">
            <v-col cols="12">
              <v-select
                dense
                label="Motivo"
                outlined
                :disabled="!esIngreso"
                v-model="tipoIngresoEgreso"
                :items="tipos"
                item-text="descripcion"
                item-value="descripcion"
                autofocus
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-show="esIngreso && tipoIngresoEgreso !== 'CAMBIO'"
                label="Motivo"
                outlined
                placeholder="Ingrese el motivo del ingreso"
                rows="2"
                style="font-size: 25px"
                row-height="20"
                v-model="descripcion"
                autofocus
              ></v-textarea>
            </v-col>
          </v-row>
          <div v-if="!isContabilizarBilletes">
            <v-list>
              <v-subheader>Dinero</v-subheader>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="success" size="36"> mdi-cash-multiple </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    <v-text-field
                      label="Cantidad"
                      style="width: 150px"
                      prefix="$"
                      autocomplete="off"
                      v-model="montoIngresado"
                      type="number"
                      required
                    ></v-text-field>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    color="primary"
                    :disabled="montoIngresado < 5"
                    text
                    block
                    @click="registrarDinero"
                  >
                    {{ textButton }}
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-row>
              <v-col cols="6">
                <v-btn large icon @click="isContabilizarBilletes = true"
                  ><v-icon>mdi-counter</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <contar-billetes
              :billetes="esIngreso ? billetesIngreso : billetesEgreso"
              :text-button="esIngreso ? 'Ingresar' : 'Retirar'"
              :cols="esIngreso ? '4' : '6'"
              @process-monto="setMontoBillete"
              @process-register="registrarDinero"
            ></contar-billetes>
          </div>
          
        </v-col>


      </v-row>
      <v-card-actions>
          <v-row align="center" justify="end" >
            <div class="text-h6">
              {{ montoIngresado | convertPesoArg }}
            </div>
            <v-btn
              color="blue"
              @click="registrarDinero"
              :disabled="montoIngresado < 1"
              class="mx-2"
              :loading="loading"
            >
              {{ esIngreso ? "Ingresar " : "Retirar" }}
            </v-btn>
          </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import ContarBilletes from "@/views/actividad/contar.billetes.vue";

export default {
  name: "ingresar.dinero",
  components: {
    ContarBilletes,
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  props: {
    esIngreso: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      descripcion: "",
      isContabilizarBilletes: true,
      montoIngresado: 0,
      montoBilletes: 0,
      billetesIngreso: [
        { valorBillete: "2000", cantidad: null },
        { valorBillete: "1000", cantidad: null },
        { valorBillete: "500", cantidad: null },
        { valorBillete: "200", cantidad: null },
        { valorBillete: "100", cantidad: null },
        { valorBillete: "50", cantidad: null },
        { valorBillete: "20", cantidad: null },
        { valorBillete: "10", cantidad: null },
        { valorBillete: "5", cantidad: null },
      ],
      billetesEgreso: [
        { valorBillete: "20000", cantidad: null },
        { valorBillete: "10000", cantidad: null },
        { valorBillete: "2000", cantidad: null },
        { valorBillete: "1000", cantidad: null },
        { valorBillete: "500", cantidad: null },
        { valorBillete: "200", cantidad: null },
        { valorBillete: "100", cantidad: null },
      ],
      tipos: [],
      tipoIngresoEgreso: this.esIngreso ? "CAMBIO" : "BUZON",
    };
  },
  computed: {
    textButton() {
      if (this.esIngreso) {
        return "Ingresar";
      } else {
        return "Retirar";
      }
    },
  },
  methods: {
    setMontoBillete(val) {
      var billetes = val;
      var saldo = 0;
      billetes.forEach((element) => {
        if (element.cantidad) {
          saldo += parseInt(element.cantidad) * parseInt(element.valorBillete);
        }
      });
      this.montoIngresado = saldo;
    },
    registrarDinero() {
      var url = "/egresos/ingresoegreso";
      this.loading = true

      var formData = {
        descripcion:
          this.descripcion == "" ? this.tipoIngresoEgreso : this.descripcion,
        monto: this.montoIngresado,
        esIngreso: this.esIngreso,
        tipoIngresoEgreso: this.tipoIngresoEgreso,
        detalles: [],
        pagaConCaja: true
        
      };

      this.axios
        .post(url, formData)
        .then((response) => {
          var text = this.esIngreso
            ? "Ingreso registrado"
            : "Retiro registrado";
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: text,
            icon: "mdi-check",
            timeout: 3000,
          });
          this.loading = false

          this.$emit("process-dinero", response.data.monto);
        })
        .catch((e) => {
          this.loading = false

          if (e.response.status === 409) {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "primary",
              text: e.response.data.data,
              icon: "mdi-information",
              timeout: 4500,
            });
          } else {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "primary",
              text: "Problema inseperado",
              icon: "mdi-information",
              timeout: 4000,
            });
          }
        });
    },
    fetchTipos() {
      if (this.esIngreso) {
        this.axios
          .get("/egresos/tipoingresoegreso?esIngreso=True")
          .then((response) => {
            this.tipos = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.axios
          .get("/egresos/tipoingresoegreso?esIngreso=False")
          .then((response) => {
            this.tipos = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
  mounted() {
    this.fetchTipos();
  },
};
</script>

<style scoped></style>
