<template>
  <v-container>
    <loading
      :active.sync="loadingProductosFrecuentes"
      :is-full-page="false"
      background-color="transparent"
      color="#FA0050"
    ></loading>
    <v-list v-show="!loadingProductosFrecuentes">
      <v-list-item-group
        v-for="producto in productos_mas_vendidos"
        :key="producto.id"
      >
        <v-list-item
        @click="validate(producto)"

          :disabled="
            inventario && producto.controlaStock && producto.cantidadstock <= 0
          "
          :class="['mx-auto', { 'grey darken-1': esPedidoYa }]"
        >
          <v-list-item-avatar v-if="esPedidoYa" size="20">
            <v-img
              src="@/assets/pedidosya/pedidoYa.svg"
              max-height="20"
              max-width="20"
              contain
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h6 text-truncate">
              {{ producto.descripcion }}
            </v-list-item-title>
            <v-list-item-subtitle class="text-h6">
              <v-chip
                small
                v-if="
                  inventario &&
                  producto.controlaStock &&
                  producto.cantidadstock <= 0
                "
                color="warning"
                >Sin stock</v-chip
              >
              <v-chip
                small
                v-if="
                  inventario &&
                  producto.controlaStock &&
                  producto.cantidadstock > 0
                "
                color="info"
                >{{ producto.cantidadstock }}</v-chip
              >
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            {{ producto.precioVenta | convertPesoArg }}
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </v-list-item-group>
    </v-list>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { add_detalle_update } from "@/services/detallesComprobante/serviceDetallesUpdate";
import { addDetalle } from "@/services/detallesComprobante/serviceDetalles";
import { mapState, mapGetters } from "vuex";

export default {
  name: "productos.frecuentes.card",
  components: {
    Loading,
  },

  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  props: {
    detalles: {
      type: Array,
      required: false,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    fromBuscador: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      productos: [],
    };
  },
  computed: {
    ...mapState("productos", ["productos_mas_vendidos"]),
    ...mapState("remitos", ["esPedidoYa"]),
    ...mapGetters("productos", ["loadingProductosFrecuentes"]),
    ...mapState("sucursales", ["configuracion"]),

    inventario() {
      return this.configuracion.productoInventario;
    },
  },
  methods: {
    validate(producto) {
      if (this.fromBuscador) {
        this.agregarProducto(producto);
      } else {
        this.agregarDetalle(producto);
      }
    },
    agregarProducto(producto) {
      this.$emit("process-producto", producto);
    },
    agregarDetalle(producto) {
      // var detalle = { cantidad: 1, isProducto: true, producto: producto };
      var detalle = {
        isProducto: true,
        cantidad: 1,
        precioVenta: producto.precioVenta,
        precioUnitario: producto.precioBase,
        descripcion_concepto: producto.descripcion,
        producto: producto,
        vat_type: producto.iva,
      };
      var det = { ...detalle };

      this.isBorrador = true;
      if (this.isUpdate) {
        add_detalle_update(this.detalles, det);
      } else {
        var response = addDetalle(this.detalles, det);
      }
      if (response) {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          right: false,
          top: false,
          color: "info",
          text: `${response}`,
          icon: "mdi-information",
          timeout: 1500,
        });
      } else {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          right: false,
          top: false,
          color: "success",
          text: "Producto agregado",
          icon: "mdi-check",
          timeout: 1500,
        });
      }
    },
  },
};
</script>

<style scoped>
.detalle {
  margin: 0;
  padding: 0;
  height: auto;
  max-height: 100%;
  overflow-y: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.grey.dark {
  background-color: #424242;
}
</style>
