<template>
  <span v-if="comprobante">
    <layout-comprobante-consultar>
      <template v-slot:title
        >Venta nº {{ comprobante.numeroVentaPorArqueo }}</template
      >

      <template v-slot:navigation-controller>
        <div>
          <v-list dense two-line class="pa-0 ma-0">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon v-text="'mdi-calendar-range'"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Fecha de venta</v-list-item-title>

                <v-list-item-subtitle>{{ fechaCreacion }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon v-text="'mdi-cash'"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Forma de pago</v-list-item-title>
                <v-list-item-subtitle
                  v-for="fp in comprobante.formasDePago"
                  :key="fp.id"
                  >{{ fp.nombre }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon v-text="'mdi-account'"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Vendedor</v-list-item-title>
                <v-list-item-subtitle>{{
                  comprobante.get_user
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon v-text="'mdi-account'"></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Tipo de comprobante</v-list-item-title>
                <v-list-item-subtitle
                  >{{ comprobante.letra }}
                  <span v-if="comprobante.es_preimpreso">
                    - Pre-impreso</span
                  ></v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </template>
      <template v-slot:button-navigation-controller>
        <v-divider></v-divider>
        <v-row class="mt-1" v-if="comprobante.origen">
          <v-col cols="12">
            <v-btn color="blue" block @click="dialogFacturar = true"
              >Facturar <v-icon>mdi-cloud-upload-outline</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <v-row class="mt-1" v-else>
          <v-col cols="12">
            <v-btn block @click="verFactura">
              <v-icon left>mdi-text-box</v-icon>Ver factura
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="red"
              block
              text
              @click="dialogAnularComprobante = true"
              :disabled="!configuracion.puedeAnularComprobante"
              >Anular comprobante</v-btn
            >
          </v-col>
        </v-row>
      </template>

      <template v-slot:vista-previa>
        <v-card height="100%">
          <v-container>
            <v-row>
              <v-col cols="7">
                <lista-detalle-comprobante
                  :total="comprobante.total"
                  :descuento="comprobante.descuento"
                  :recargo="comprobante.recargo"
                  :motivoDescuento="comprobante.motivoDescuento"
                  v-if="detalles_comprobante.length > 0"
                  :detalles="detalles_comprobante"
                ></lista-detalle-comprobante>
              </v-col>
              <v-col cols="5">
                <v-container>
                  <venta-cliente-vista-previa
                    v-if="cliente"
                    :formCliente="nombreEntidad"
                    :formEnvio="nombreEntidadEnvio"
                    :cliente="cliente"
                  ></venta-cliente-vista-previa>
                  <v-card
                    v-else
                    max-height="600"
                    flat
                    max-width="375"
                    class="mx-auto overflow-y-auto"
                  >
                    <v-img
                      contain
                      src="@/assets/man-woman.jpg"
                      height="150px"
                      dark
                    >
                      <v-row align="end" class="fill-height">
                        <v-card-title>
                          <div class="display-1 text-center">
                            Cliente desconocido
                          </div>
                        </v-card-title>
                      </v-row>
                    </v-img>
                  </v-card>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
    </layout-comprobante-consultar>

    <!-- <v-dialog v-model="dialogFacturar" persistent width="500">
      <v-card color="#242526" v-if="!preImpreso">
        <v-card-title>
          <div style="font-size: 20px"><b>Elige el tipo de facturación</b></div>

          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon color="grey" @click="dialogFacturar = false"
              >mdi-close</v-icon
            >
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div
            class="d-flex align-center justify-center mb-6"
            style="height: 100%"
          >
            <v-row justify="center">
              <v-col>
                <v-row class="mt-1" justify="center">
                  <v-col cols="4">
                    <v-hover v-slot:default="{ hover }">
                      <v-card
                        @click="registrarFacturaElectronica"
                        :loading="loadingFacturar"
                        height="150px"
                        width="150px"
                        class="d-flex align-center"
                        :color="hover ? '#313233' : ''"
                      >
                        <v-container class="text-center">
                          <div>
                            <v-icon color="blue" large>mdi-tag</v-icon>
                          </div>
                          <h3>Factura electrónica</h3>
                          <span style="color: #bdbdbd"> </span>
                        </v-container>
                      </v-card>
                    </v-hover>
                  </v-col>
                  <v-col cols="4">
                    <v-hover v-slot:default="{ hover }">
                      <v-card
                        @click="preImpreso = true"
                        height="150px"
                        width="150px"
                        class="d-flex align-center"
                        :color="hover ? '#313233' : ''"
                      >
                        <v-container class="text-center">
                          <div>
                            <v-icon color="blue" large>mdi-tag-multiple</v-icon>
                          </div>
                          <h3>Pre-impreso</h3>
                          <span style="color: #bdbdbd">
                            Asocia una factura en papel con la venta.
                          </span>
                        </v-container>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
      <factura-pre-impresa
        v-if="preImpreso"
        :comprobante="comprobante"
        @go-back="preImpreso = false"
        @close-dialog="dialogFacturar = false"
      ></factura-pre-impresa>
    </v-dialog>
    <v-dialog v-model="dialogConsultarPreImpreso" width="500">
      <factura-pre-impresa-consultar
        v-if="dialogConsultarPreImpreso"
        :comprobante="comprobante"
        @close-dialog="dialogConsultarPreImpreso = false"
      ></factura-pre-impresa-consultar>
    </v-dialog> -->
    <v-dialog v-model="dialogAnularComprobante" persistent width="500">
      <v-card color="#242526">
        <v-card-title>
          <b>
            <div style="font-size: 22px">¿Desea anular el comprobante?</div>
          </b>

          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon color="grey" @click="dialogAnularComprobante = false"
              >mdi-close</v-icon
            >
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="mt-2">
            <div style="font-size: 16px">Seleccione el motivo</div>
            <div class="text-center mt-2">
              <v-select
                outlined
                data-vv-name="motivo"
                v-validate="'required'"
                :error-messages="errors.collect('motivo')"
                v-model="motivoAnulacion.descripcion"
                :items="motivosAnulacion"
                item-value="descripcion"
                item-text="descripcion"
                label="Motivo"
              >
              </v-select>
              <v-textarea
                v-if="motivoAnulacion.descripcion == 'Otro'"
                label="Comentarios"
                outlined
                data-vv-name="comentario"
                v-validate="'max:100'"
                :error-messages="errors.collect('comentario')"
                rows="3"
                counter="100"
                style="font-size: 25px"
                row-height="20"
                v-model="motivoAnulacion.comentario"
              ></v-textarea>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="info" @click="dialogAnularComprobante = false" text>
            <b> Permanecer en la página </b>
          </v-btn>
          <v-btn
            :disabled="
              errors.any() || motivoAnulacion.descripcion == 'Otro'
                ? motivoAnulacion.comentario
                  ? false
                  : true
                : false
            "
            @click="validateForm"
            :loading="loadingAnular"
            color="info"
          >
            <b> Anular comprobante </b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import VentaClienteVistaPrevia from "@/components/venta/cliente/venta.cliente.vista-previa";
import ListaDetalleComprobante from "@/components/comprobante/detalle/comprobante.detalles.info.vue";
import InfoProveedor from "@/components/proveedor/proveedor.info";
import LayoutComprobanteConsultar from "@/layouts/layout.comprobante.consultar";
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";
import FacturaPreImpresa from "@/components/venta/factura/venta.factura.pre-impreso.form.vue";

import FacturaPreImpresaConsultar from "@/components/venta/factura/venta.factura.pre-impreso.consultar.vue";

import VentasApi from "@/api/venta/venta";
import AfipFiscalAPI from "@/api/afipfiscal";

import { mapState } from "vuex";

export default {
  name: "venta.consultar.detalle",

  $_veeValidate: {
    validator: "new",
  },
  filters: {
    convertPesoArg(value) {
      return `$${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  components: {
    ListaDetalleComprobante,
    LayoutComprobanteConsultar,
    DialogConfirmationClose,
    InfoProveedor,
    VentaClienteVistaPrevia,
    FacturaPreImpresa,
    FacturaPreImpresaConsultar,
  },
  data() {
    return {
      preImpreso: false,
      dialogConsultarPreImpreso: false,
      motivoAnulacion: {
        descripcion: "",
        comentario: "",
      },
      motivosAnulacion: [],
      dialogAnularComprobante: false,
      comprobante: null,
      detalles_comprobante: [],
      cliente: null,
      nombreEntidad: null,
      nombreEntidadEnvio: null,
      dialog: false,
      dialogFacturar: false,
      loadingFacturar: false,
      loadingAnular: false,
    };
  },
  computed: {
    ...mapState("sucursales", ["configuracion"]),
    fechaCreacion() {
      return this.moment(this.comprobante.fechaAlta).format("lll");
    },

    hay_detalles_para_ver() {
      if (this.detalles_comprobante.length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async getMotivosAnulacion() {
      try {
        const response = await this.axios.get("comprobantes/motivosanulacion");
        this.motivosAnulacion = response.data;
      } catch (error) {}
    },
    async registrarFacturaElectronica() {
      this.loadingFacturar = true;
      try {
        await VentasApi.facturar(this.comprobante.id);

        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: `Comprobante facturado`,
          icon: "mdi-check",
          timeout: 3000,
        });
        this.dialogFacturar = false;

        this.$router.push("/ventas");
      } catch (error) {
        console.log(error);
        this.loadingFacturar = false;
        var message = error.response.data.detail
          ? error.response.data.detail
          : `Hubo un problema en la facturación`;
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "red",
          text: message,
          icon: "mdi-information",
          timeout: 3000,
        });
      }
    },
    verFactura() {
      if (this.comprobante.es_preimpreso) {
        this.dialogConsultarPreImpreso = true;
      } else {
        AfipFiscalAPI.show(this.comprobante.id)
          .then((response) => {
            window.open(response.data.pdf_url, "_blank");
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    validateForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.anularComprobante();
        } else {
          this.$validator.validate();
        }
      });
    },
    async anularComprobante() {
      this.loadingAnular = true;

      var id = this.$route.params.id;
      try {
        const response = await VentasApi.anular(id, this.motivoAnulacion);

        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: `Venta anulada`,
          icon: "mdi-check",
          timeout: 3000,
        });
        this.dialogAnularComprobante = false;
        this.loadingAnular = false;

        this.$router.push("/ventas");
      } catch (error) {
        this.loadingAnular = false;
        var message = error.response.data.detail
          ? error.response.data.detail
          : `No se pudo anular la venta.`;
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "red",
          text: message,
          icon: "mdi-information",
          timeout: 3000,
        });
      }
    },
    async fetchComprobante(id) {
      var response = await VentasApi.show(id);
      this.comprobante = response.data;
      
      this.detalles_comprobante = this.comprobante.detalles_comprobante;
      this.cliente = this.comprobante.cliente;
      this.nombreEntidad = this.comprobante.nombreEntidad;

      this.nombreEntidadEnvio = this.comprobante.nombreEntidad.envio;
    },
  },

  created() {
    this.$store.commit("SET_LAYOUT", { layout: "layout-basic", withNav: true });

    let id = this.$route.params.id;

    this.fetchComprobante(id);
    this.getMotivosAnulacion();
  },
};
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
