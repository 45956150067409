<template>
  <v-list dense>
    <v-list-item dense>
      <v-list-item-content>
        <v-list-item-title>Cantidad artículos</v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon>{{ detalles.length }} </v-list-item-icon>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Subtotales</v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon
        ><h2>{{ calcularSubtotal() | convertPesoArg }}</h2>
      </v-list-item-icon>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "pedido.detalle.subtotal",
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  props: {
    detalles: {
      type: Array,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      required: false,
      default: false
    },
    
    tipoRedondeo: {
      type: String,
      required: true,
      default: "entero", // Valor predeterminado
      validator: (value) => {
        return ["decimales", "entero"].includes(value);
      },
    },
  },
 computed: {
  ...mapState("comprobantes", ["precioVentaManual"]),

 },
  methods: {
    ...mapMutations("ventas", [
      "SET_SUBTOTAL",
      "RESET_FORMAS_DE_PAGO_SELECCIONADAS",
    ]),

    calcularSubtotal() {
      let subtotal = 0;
      for (let index = 0; index < this.detalles.length; index++) {
        var precio = this.detalles[index].precioVenta ? this.detalles[index].precioVenta : this.detalles[index].precioUnitario
          if (this.isUpdate){
            subtotal += parseFloat(precio) * parseFloat(this.detalles[index].cantidad);

          }else{
            subtotal += parseFloat(precio) * parseFloat(this.detalles[index].cantidad);
          }

      }

      // Aplicar el tipo de redondeo según 'tipoRedondeo'
      if (this.tipoRedondeo === "entero") {
        subtotal = Math.round(subtotal); // Redondea al entero más cercano
      } else if (this.tipoRedondeo === "decimales") {
        subtotal = parseFloat(subtotal.toFixed(2)); // Redondea a dos decimales
      }

      this.SET_SUBTOTAL(subtotal);
      return subtotal;

    },
  },
};
</script>

<style lang="scss" scoped></style>
