<template>
  <div>
    <v-text-field
      class="mr-1"
      :loading="isLoading"
      v-model="numeroComprobanteLocal"
      clearable
      label="Nº de comprobante"
      outlined
      id="inputSearch"
      v-validate="'max:10'"
      :error-messages="errors.collect('inputSearch')"
      data-vv-name="código"
      type="number"
      autocomplete="off"
      autofocus
      @focus.native="$event.target.select()"
      @click:clear="clearData"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: "VentasBuscador",
  $_veeValidate: {
    validator: "new",
  },
  props: {
    // Define el prop con v-model personalizado
    numeroComprobante: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isLoading: false,
      pedidos: [],
      numeroComprobanteLocal: this.numeroComprobante 
    };
  },
  methods: {
    focus() {
      this.$refs.searchCmp.focus();
    },
    clearData() {
      this.numeroComprobanteLocal = "";  // Limpia el input local
      this.$emit("update:numeroComprobante", "");  // Limpia el valor del padre
    },
    // Emitir el cambio al padre manualmente
    updateNumeroComprobante(val) {
      this.$emit("update:numeroComprobante", val);
    }
  },
  watch: {
    // Si cambia el valor local, emitimos el cambio al padre
    numeroComprobanteLocal(val) {
      this.updateNumeroComprobante(val);
    },
    // Si el padre cambia el valor, sincronizamos con el local
    numeroComprobante(val) {
      this.numeroComprobanteLocal = val;
    }
  }
};
</script>
