<template>
  <layout-registrar-producto>
    <template v-slot:up-title> Productos > Modificar </template>

    <template v-slot:title>
      <v-list>
        <v-list-item class="pa-0">
          <v-list-item-title>
            <h1 style="font-size: 30px">Modificar producto</h1>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
    </template>

    <template v-slot:navigation-controller>
      <v-container v-if="producto">
        <template v-if="step == 1" name="step-1">
          <form-producto-es-elaboracion-propia
            :outlined="true"
            :producto="producto"
          >
          </form-producto-es-elaboracion-propia>
          <form-producto-proveedor
            @set_proveedor="setProveedor"
            :outlined="true"
            :producto="producto"
          >
          </form-producto-proveedor>
          <form-producto-categoria
            @set_categoria="setCategoria"
            :producto="producto"
          >
          </form-producto-categoria>
          <form-producto-marca
            @set_marca="setMarca"
            :outlined="true"
            :producto="producto"
          >
          </form-producto-marca>
          <div>
            <v-divider></v-divider>
            <div class="mt-2">
              <b>Nombre del producto</b>
            </div>
            <div style="font-size: 14px">Ingrese el nombre del producto.</div>
            <div>
              <form-producto-descripcion :producto="producto">
              </form-producto-descripcion>
            </div>
          </div>
          <div>
            <v-divider></v-divider>
            <div class="mt-1">
              <b>Código de barra</b>
            </div>
            <div style="font-size: 14px">
              Escanea el código de barra del producto o escríbelo manualmente.
            </div>
            <div class="mt-1">
              <form-producto-barcode
                :producto="producto"
              ></form-producto-barcode>
            </div>
          </div>
        </template>
        <template name="step-2" v-if="step == 2">
          <div name="Precio de costo">
            <div>
              <b>Precio de costo</b>
            </div>
            <div style="font-size: 14px">
              Ingresa el precio de costo o compra del producto.
            </div>
            <div class="mt-4">
              <form-producto-precio-costo
                :producto="producto"
                :outlined="true"
                icon-button="mdi-calculator"
                :append-outer-icon="false"
                @click-button-append="openCalculator()"
              ></form-producto-precio-costo>
            </div>
            <!-- <calculadora-precio-venta
              :producto="producto"
              v-if="dialogCalculator"
              @close="openCalculator"
              @change-precio-costo="setPrecioCosto"
              @change-ganancia="setGanancia"
            ></calculadora-precio-venta> -->
          </div>

          <div name="Ganancia">
            <v-divider></v-divider>
            <div class="mt-3">
              <b>Ganancia del producto</b>
            </div>
            <div style="font-size: 14px">
              Ingresa el procentaje de ganancia que tendrá el producto.
            </div>
            <div class="mt-4">
              <form-producto-ganancia :producto="producto" :outlined="true">
              </form-producto-ganancia>
            </div>
          </div>
          <div name="Flete">
            <v-divider></v-divider>
            <div class="mt-1">
              <b>Costos extras</b>
            </div>
            <div style="font-size: 14px">
              Ingresa un porcentaje de costo extra que lleva el producto.
            </div>
            <div class="mt-1">
              <form-producto-flete
                :producto="producto"
                :outlined="true"
              ></form-producto-flete>
            </div>
          </div>


        </template>
        <template name="step-3" v-if="step == 3">
          <div class="mb-2">
            <h3>Configuración Contable</h3>
          </div>

          <!-- Sección: Rubro de Venta -->
          <div>
            <h4>Rubro de Venta</h4>
            <v-divider></v-divider>
            <div class="mt-1">
              <RubroVentaListSelect
                :value="rubroVenta"
                @process-select="setRubroVenta"
                title="Seleccionar Rubro Venta"
              ></RubroVentaListSelect>
            </div>
            <div class="mt-0">
              <b>IVA Venta</b>
            </div>
            <div style="font-size: 14px">Impuesto sobre el valor añadido</div>
            <div class="mt-1">
              <AlicuotaIvaSelect
                class="mt-1 mb-1"
                :iva="ivaVenta"
                :isEdit="false"
                @update:iva="setIva(producto, $event)"
              ></AlicuotaIvaSelect>
            </div>
          </div>

          <!-- Sección: Rubro de Compra -->
          <div class="mt-4">
            <h4>Rubro de Compra</h4>
            <v-divider></v-divider>
            <div class="mt-1">
              <RubroCompraListSelect
                :value="rubroCompra"
                @process-select="setRubroCompra"
                title="Seleccionar Rubro Compra"
              ></RubroCompraListSelect>
            </div>
            <div class="mt-0">
              <b>IVA Compra</b>
            </div>
            <div style="font-size: 14px">Impuesto sobre el valor añadido</div>
            <div class="mt-1">
              <AlicuotaIvaSelect
                :isEdit="false"
                :iva="ivaCompra"
                @update:iva="setIvaCompra(producto, $event)"
              ></AlicuotaIvaSelect>
            </div>
          </div>
        </template>
        <template name="step-4" v-if="step == 4">
          <div name="Precio de venta calculado" class="mt-3">
            <v-divider></v-divider>
            <div class="mt-5">
              <b>Precio de Venta Manual</b>
            </div>
            <div style="font-size: 14px">
              Este es el precio de venta que has ingresado manualmente. Se
              redondeado al entero más cercano.
            </div>
            <div class="mt-1">
              <FormProductoPrecioVenta
                :tipoRedondeo="'entero'"
                :esPrecioVentaManual="true"

                :producto="producto"
                :outlined="true"
              ></FormProductoPrecioVenta>
            </div>
          </div>

          <div name="Precio de venta calculado">
            <v-divider></v-divider>
            <div class="mt-1">
              <b>Precio de Venta Calculado</b>
            </div>
            <div style="font-size: 14px">
              Este es el precio de venta calculado automáticamente basado en el
              costo y la ganancia. Está redondeado a dos decimales.
            </div>
            <div class="mt-1">
              <FormProductoPrecioVenta
                :producto="producto"
                :esPrecioVentaManual="false"

                :outlined="true"
                :disabled="true"
                :tipoRedondeo="'decimales'"
              ></FormProductoPrecioVenta>
            </div>
          </div>
          <v-list dense class="mt-0 pt-0">
            <v-list-item class="mt-0 pt-0">
              <v-list-item-avatar>
                <v-img
                  src="@/assets/pedidosya/pedidoya.png"
                  max-height="30"
                  max-width="30"
                  contain
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <div style="font-weight: 600">Vender en PedidosYA</div>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox
                  dense
                  hide-details=""
                  class="text-center justify-center"
                  v-model="producto.esPedidoYa"
                  value
                >
                </v-checkbox>
              </v-list-item-action>
            </v-list-item>

            <v-list-item v-show="producto.esPedidoYa">
              <v-list-item-content>
                <v-list-item-title>
                  <v-text-field
                    :prefix="producto.porcentajePY ? '%' : ''"
                    v-model="producto.porcentajePY"
                    v-validate="'decimal:2|max:30'"
                    :error-messages="errors.collect('porcentaje')"
                    data-vv-name="porcentaje"
                    type="number"
                    label="Porcentaje de racargo"
                    outlined
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    @change="calcularPrecioPY()"
                    maxlength="5"
                    class="ma-1"
                  ></v-text-field>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-container>
    </template>

    <template v-slot:navigation-stepper>
      <v-row>
        <v-col>
          <v-progress-linear
            color="indigo"
            rounded
            value="100"
            height="8"
          ></v-progress-linear>
        </v-col>
        <v-col>
          <v-progress-linear
            color="indigo"
            rounded
            :value="step > 1 ? '100' : '0'"
            height="8"
          ></v-progress-linear>
        </v-col>
        <v-col>
          <v-progress-linear
            color="indigo"
            rounded
            :value="step > 2 ? '100' : '0'"
            height="8"
          ></v-progress-linear>
        </v-col>
        <v-col>
          <v-progress-linear
            color="indigo"
            rounded
            :value="step > 3 ? '100' : '0'"
            height="8"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </template>
    <template v-slot:navigation-append="{ reload }">
      <v-btn
        v-show="step == 1"
        large
        class="mb-2"
        color="primary"
        @click="
          validateForm(2);
          reload();
        "
        block
        ><b style="font-size: 17px">Siguiente</b>
      </v-btn>
      <v-row>
        <v-col>
          <v-btn
            large
            v-show="step == 2"
            class="mb-2"
            @click="
              nextStep(1);
              reload();
            "
            block
            ><b style="font-size: 17px">Anterior</b></v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            large
            v-show="step == 2"
            class="mb-2"
            color="primary"
            @click="validateForm(3)"
            block
            ><b style="font-size: 17px">Siguiente</b></v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            large
            v-show="step == 3"
            class="mb-2"
            @click="
              nextStep(2);
              reload();
            "
            block
            ><b style="font-size: 17px">Anterior</b></v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            large
            v-show="step == 3"
            class="mb-2"
            color="primary"
            @click="validateForm(4)"
            block
            ><b style="font-size: 17px">Siguiente</b></v-btn
          >
        </v-col>
      </v-row>
      <v-row v-show="step == 4">
        <v-col>
          <v-btn
            large
            v-show="step == 4"
            class="mb-2"
            @click="nextStep(3)"
            block
            ><b style="font-size: 17px">Anterior</b></v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            v-show="step == 4"
            large
            class="mb-2"
            color="success"
            :loading="loading"
            :disabled="errors.any()"
            @click="validateForm()"
            block
            ><b style="font-size: 17px">Guardar</b>
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <template v-slot:vista-previa>
      <!-- <v-container style="height: 100vh; max-height: calc(100vh - 64px)"> -->
      <v-container v-if="!loadingFetch">
        <v-row align="center" justify="center" class="mt-2 mb-2 ml-2 mr-2">
          <v-col cols="12">
            <v-card>
              <v-container>
                <div class="mb-2">Vista previa</div>
                <producto-vista-previa
                  :marca="marca"
                  :categoria="categoria"
                  :proveedor="proveedor"
                  :producto="producto"
                  :precioPY="calcularPrecioPY()"
                ></producto-vista-previa>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout-registrar-producto>
</template>

<script>
import { administrarMixin } from "@/mixins/producto/administrarMixin.js";
import LayoutRegistrarProducto from "@/layouts/productos/layout.registrar.producto";
import ProductoVistaPrevia from "@/components/producto/producto.vista.previa.vue";

import FormProductoProveedor from "@/components/producto/form/form.producto.proveedor.vue";
import FormProductoCategoria from "@/components/producto/form/form.producto.categoria.vue";
import FormProductoDescripcion from "@/components/producto/form/form.producto.descripcion.vue";
import FormProductoBarcode from "@/components/producto/form/form.producto.barcode.vue";

import ProductoForm from "@/components/producto/form/producto.form";
import FormProductoMarca from "@/components/producto/form/form.producto.marca.vue";
import FormProductoPrecioCosto from "@/components/producto/form/form.producto.precio.costo.vue";
import FormProductoGanancia from "@/components/producto/form/form.producto.ganancia.vue";
import FormProductoIva from "@/components/producto/form/form.producto.iva.vue";
import FormProductoFlete from "@/components/producto/form/form.producto.flete.vue";
import FormProductoPrecioVenta from "@/components/producto/form/form.producto.precio.venta.vue";
import FormProductoInventario from "@/components/producto/form/form.producto.inventario.vue";
import FormProductoEsElaboracionPropia from "@/components/producto/form/FormProductoEsElaboracionPropia.vue";

import CalculadoraPrecioVenta from "@/components/producto/actualizar/producto.calculadora.precioVenta.vue";
import AlicuotaIvaSelect from "@/components/util/AlicuotaIvaSelect.vue";
import RubroVentaListSelect from "@/components/contabilidad/RubroVentaListSelect.vue";
import RubroCompraListSelect from "@/components/contabilidad/RubroCompraListSelect.vue";

import { mapActions, mapMutations } from "vuex";
import ProductosAPI from "@/api/productos";

export default {
  name: "producto.actualizar",
  components: {
    FormProductoCategoria,
    FormProductoDescripcion,
    FormProductoBarcode,
    ProductoForm,
    LayoutRegistrarProducto,
    FormProductoProveedor,
    FormProductoMarca,
    FormProductoPrecioCosto,
    FormProductoGanancia,
    FormProductoIva,
    FormProductoFlete,
    FormProductoPrecioVenta,
    ProductoVistaPrevia,
    FormProductoInventario,
    CalculadoraPrecioVenta,
    FormProductoEsElaboracionPropia,
    AlicuotaIvaSelect,
    RubroVentaListSelect,
    RubroCompraListSelect,
  },
  $_veeValidate: {
    validator: "new",
  },
  mixins: [administrarMixin],
  data() {
    return {
      loadingFetch: false,
      producto: null,
      dialogCalculator: false,
    };
  },
  computed: {
    rubroCompra() {
      return this.producto.contabilidad?.rubro_compra || {};
    },
    rubroVenta() {
      return this.producto.contabilidad?.rubro_venta || {};
    },
    ivaCompra() {
      return this.producto.contabilidad?.rubro_compra?.iva || {};
    },
    ivaVenta() {
      return this.producto.contabilidad?.rubro_venta?.iva || {};
    },
  },

  methods: {
    ...mapActions("productos", [
      "actualizar_producto",
      "fetch_productos_mas_vendidos",
    ]),
    setIvaCompra(producto, vatType) {
      // this.producto.contabilidad.rubro_compra.iva = vatType;
      this.producto.iva_compra = vatType;
    },
    setIva(producto, vatType) {
      // this.producto.contabilidad.rubro_venta.iva = vatType;

      this.producto.iva = vatType;
    },
    ensureContabilidad(producto) {
      if (!producto.contabilidad) {
        producto.contabilidad = {
          rubro_compra: {
            id: null,
            iva: { description: null },
          },
          rubro_venta: {
            id: null,
            iva: { description: null },
          },
        };
      }

     
    },

    setRubroCompra(rubro) {
      const { id, iva } = rubro;
      this.ensureContabilidad(this.producto);
      this.producto.contabilidad.rubro_compra.id = id;
      this.producto.contabilidad.rubro_compra.iva = iva;
      this.producto.iva_compra = iva;

      console.log(
        "Contabilidad desde setRubroCompra",
        this.producto.contabilidad
      );
    },
    setRubroVenta(rubro) {
      const { id, iva } = rubro;

      console.log(rubro);

      console.log("iva", iva);

      console.log(
        "Contabilidad desde setRubroVenta",
        this.producto.contabilidad
      );
      // Asegurar que producto.contabilidad exista
      this.ensureContabilidad(this.producto);

      // Asignar valores al rubro de venta
      this.producto.contabilidad.rubro_venta.id = id;
      this.producto.contabilidad.rubro_venta.iva = iva;

      // Actualizar el IVA del producto
      this.producto.iva = iva;
    },

    openCalculator() {
      this.dialogCalculator = !this.dialogCalculator;
    },
    validateForm(nextStep) {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          if (nextStep) {
            this.nextStep(nextStep);
          } else {
            this.actualizarProducto(this.producto);
          }
        } else {
          this.$validator.validate();
        }
      });
    },
    actualizarProducto(p) {
      this.loading = true;

      this.actualizar_producto(p)
        .then((response) => {
          // this.update_product(data);
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Producto Actualizado",
            icon: "mdi-check",
            timeout: 2000,
          });
          this.loading = false;
          this.fetch_productos_mas_vendidos();
          this.$router.go(-1);
        })
        .catch((e) => {
          this.loading = false;
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: "Problema inesperado",
            icon: "mdi-information",
            timeout: 3000,
          });
          this.loading = false;
        });
    },
    setPrecioCosto(precio) {
      this.producto.precioCosto = precio;
    },
    setGanancia(precio) {
      this.producto.ganancia = precio;
    },

    fetchProducto(id) {
      this.loadingFetch = true;

      ProductosAPI.show(id).then((response) => {
        this.producto = response.data;
        this.producto.categoriaArbol
          ? null
          : (this.producto.categoriaArbol = { id: null });
        this.setCategoria(response.data.categoriaArbol);
        this.setMarca(response.data.marca);
        this.setProveedor(response.data.proveedor);
        this.loadingFetch = false;
      });
    },
  },
  created() {
    if (this.$route.params.producto === undefined) {
      this.fetchProducto(this.$route.params.id);
    } else {
      this.loadingFetch = true;
      var producto = this.$route.params.producto;
      if (!producto.categoriaArbol) {
        producto.categoriaArbol = { id: null };
      }

      this.setCategoria(producto.categoriaArbol);
      this.setMarca(producto.marca);
      this.setProveedor(producto.proveedor);

      producto.categoriaArbol = producto.categoriaArbol
        ? producto.categoriaArbol
        : { id: null };
      this.producto = producto;
      this.loadingFetch = false;
    }
  },
};
</script>

<style scoped>
.v-list {
}
</style>
