var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"row-pointer transparent",attrs:{"headers":_vm.headers,"items":_vm.facturas,"no-data-text":"No se encontraron resultados","hide-default-footer":""},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.fechaAlta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.fechaAlta).format("ll"))+" ")]}},{key:"item.fecha_contable",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.fecha_contable).format("ll"))+" ")]}},{key:"item.origen",fn:function(ref){
var item = ref.item;
return [(!_vm.pendienteFacturacion && item.origen)?_c('v-icon',[_vm._v("mdi-cloud-question")]):_vm._e(),(!_vm.pendienteFacturacion && !item.origen)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-cloud-check-outline")]):_vm._e(),(_vm.pendienteFacturacion && item.origen)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-cloud-alert")]):_vm._e()]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("convertPesoArg")(item.total))+" ")]}}])}),_c('v-row',[_c('v-col',[(_vm.facturas)?_c('v-pagination',{attrs:{"length":_vm.pages,"value":_vm.paginacion.page,"circle":""},on:{"input":_vm.paginationChangeHandler}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }