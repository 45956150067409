<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-row align="center" justify="center">
          <v-col cols="12" md="8">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline" tag="h3">
                  Configuración de la cuenta corriente
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-row>
              <v-col>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Estado de la cuenta</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title
                      ><v-chip
                        class="ma-2"
                        :color="cuentaCorriente.is_habilitado ? 'green' : 'red'"
                        label
                      >
                        {{
                          cuentaCorriente.is_habilitado
                            ? "Habilitada"
                            : "Deshabilitada"
                        }}
                      </v-chip>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action
                    ><div class="caption" style="color: blue">
                      <a @click="setHabilitadoDes">
                        {{
                          cuentaCorriente.is_habilitado
                            ? "Deshabilitar"
                            : "Habilitar"
                        }}
                      </a>
                    </div>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Limite de la cuenta</v-list-item-title>
                    <v-list-item-subtitle
                      v-if="!cuentaCorriente.tiene_limite_venta"
                    >
                      <div class="text-wrap">Esta cuenta no tiene un límite de venta establecido.</div>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content>
                    <v-list-item-title>
                      <v-chip
                        class="ma-2"
                        :color="
                          cuentaCorriente.tiene_limite_venta ? 'green' : 'red'
                        "
                        label
                      >
                        {{
                          cuentaCorriente.tiene_limite_venta
                            ? "Habilitada"
                            : "Deshabilitada"
                        }}
                      </v-chip>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action
                    ><div class="caption" style="color: blue">
                      <a @click="setLimiteCuenta">
                        {{
                          cuentaCorriente.tiene_limite_venta
                            ? "Deshabilitar"
                            : "Habilitar"
                        }}
                      </a>
                    </div>
                  </v-list-item-action>
                  <!-- <v-list-item-action
                    ><v-switch
                      v-model="cuentaCorriente.tiene_limite_venta"
                      label="Tiene límite de venta"
                    ></v-switch>
                  </v-list-item-action> -->
                </v-list-item>

                <v-card
                  :disabled="!cuentaCorriente.tiene_limite_venta"
                  flat
                  class="transparent pl-0 pr-0"
                >
                  <v-card-title
                    >Datos de la cuenta

                    <v-spacer></v-spacer>

                    <div class="caption" style="color: blue">
                      <a @click="setEditCuenta">Modificar datos de la cuenta</a>
                    </div>
                  </v-card-title>
                </v-card>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Abierta el
                        {{ moment(cuentaCorriente.fecha_alta).format("lll") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Limite de cuenta</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        cuentaCorriente.maximo_venta | convertPesoArg
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        >Plazo de vencimiento</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="cuentaCorriente.plazo_vencimiento"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-form-generic
      v-if="dialogFormGeneric && cuentaCorrienteEdit"
      @close-dialog="closeDialog"
      @process-save="saveCuentaCorriente"
    >
      <template v-slot:titulo>Modificar datos de la cuenta </template>
      <template v-slot:message>
        <v-text-field
          label="Límite de cuenta"
          outlined
          v-model="cuentaCorrienteEdit.maximo_venta"
        >
        </v-text-field>
        <v-select
          outlined
          label="Plazo de vencimiento"
          :items="plazosVencimientos"
          item-text="nombre"
          item-value="nombre"
          v-model="cuentaCorrienteEdit.plazo_vencimiento"
        >
        </v-select>
      </template>
      <template v-slot:buttonCancel>Cancelar</template>
      <template v-slot:buttonOK>Guardar</template>
    </dialog-form-generic>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import ClienteUpdate from "@/views/cliente/ClienteUpdate";

import LayoutDialogDelete from "@/layouts/LayoutDialogDelete";
import DialogFormGeneric from "@/components/dialogs/DialogFormGeneric";
import cuentacorriente from "../../../store/modules/clientes/cuentacorriente";

export default {
  name: "ClienteListCtaCte",
  components: {
    ClienteUpdate,
    LayoutDialogDelete,
    DialogFormGeneric,
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  props: {
    search: {
      type: String,
    },
  },
  data() {
    return {
      dialogFormGeneric: false,
      fieldUpdate: null,
      editCuenta: null,
      plazosVencimientos: [],
      cuentasCorrientes: [
        {
          id: 1,
          fecha: "11-01-22",
          comprobabte: "001-NV-0000345",
          tipoMovimiento: "Compraste 1 Kg",
          haber: "$ 0",
          saldo: "$10.00",
          vigente: true,
        },
        {
          id: 2,
          fecha: "12-01-22",
          comprobabte: "001-RECIBO-0000346",
          tipoMovimiento: "Pago",
          haber: "$ 600.00",
          saldo: "$ -100",
          vigente: true,
        },
      ],

      headers: [
        { text: "Fecha", value: "fecha" },
        { text: "Nº de comprobante", value: "comprobabte" },
        { text: "Tipo de movimiento", value: "tipoMovimiento" },
        { text: "Detalle", value: "verDetalle" },

        { text: "Importe", value: "haber" },

        { text: "Saldo", value: "saldo", sortable: false },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapState("clienteCuentaCorriente", [
      "cuentaCorriente",
      "cuentaCorrienteEdit",
    ]),
  },
  methods: {
    ...mapMutations("clienteCuentaCorriente", [
      "setCuentaCorriente",
      "setCuentaCorrienteEdit",
    ]),

    ...mapActions("clienteCuentaCorriente", [
      "updateCuentaCorriente",
      "setHabilitado",
      "setTieneLimiteCuenta",
    ]),
    setEditCuenta() {
      this.setCuentaCorrienteEdit(this.cuentaCorriente);
      this.dialogFormGeneric = true;
    },
    saveCuentaCorriente() {
      this.updateCuentaCorriente(this.cuentaCorrienteEdit)
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "green",
            text: `Actualizado`,
            icon: "mdi-check",
            timeout: 2500,
          });
          this.dialogFormGeneric = false;
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: `${e.response.data}`,
            icon: "mdi-information",
            timeout: 3000,
          });
          this.dialogFormGeneric = false;
        });
    },
    setHabilitadoDes() {
      const id_cliente = this.$route.params.id_cliente;

      this.setHabilitado(id_cliente)
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "green",
            text: `Actualizado`,
            icon: "mdi-check",
            timeout: 2500,
          });
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: `${e.response.data}`,
            icon: "mdi-information",
            timeout: 3000,
          });
        });
    },
    setLimiteCuenta() {
      const id_cliente = this.$route.params.id_cliente;
      // this.cuentaCorriente.tiene_limite_venta = !this.cuentaCorriente.tiene_limite_venta
      console.log(id_cliente);

      this.setTieneLimiteCuenta(id_cliente)
        .then((response) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "green",
            text: `Actualizado`,
            icon: "mdi-check",
            timeout: 2500,
          });
        })
        .catch((e) => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: `${e.response.data}`,
            icon: "mdi-information",
            timeout: 3000,
          });
        });
    },
    closeDialog() {
      this.dialogFormGeneric = false;
    },

    handleClick(a) {
      this.$router.push({
        name: "clientesCuentasCorrientesDetalle",
        params: { id_cliente: a.id },
      });
    },
  },
  created() {
    this.axios.get("/soporte/plazovencimiento").then((plazos) => {
      this.plazosVencimientos = plazos.data;
    });
  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
a {
  text-decoration: none !important;
}
a:hover {
  color: #3483fa;
  text-decoration: none;
  cursor: pointer;
}
</style>
