<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="clientes"
          class="row-pointer transparent"
          @click:row="handleClick"
          :search="search"
          :loading="loading"
          dark
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Lista de clientes</v-toolbar-title>
            </v-toolbar>
          </template>


          <template v-slot:no-data>
            <v-alert :value="true" color="info" icon="mdi-information"
              >No hay clientes registrados.</v-alert
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";


export default {
  name: "ClientesList",
  props: {
    search: {
      type: String,
    },
  },
  data() {
    return {
      headers: [
        { text: "Nombre", value: "persona.nombre_completo" },
        { text: "Dirección", value: "persona.direccion", sortable: false },
        { text: "Teléfono", value: "persona.telefono", sortable: false },
        { text: "E-mail", value: "persona.email", sortable: false },
        // { text: "Localidad", value: "localidad.nombre", sortable: false },
        // {
        //   text: "Provincia",
        //   value: "localidad.provincia.nombre",
        //   sortable: false,
        // },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapState("cliente", ["clientes"]),
  },
  methods: {
    ...mapActions("cliente", ["fetchClientes"]),

    handleClick(item) {
      this.$router.push({ path: `/clientes/${item.id}` });
    },
  },
  created() {
    if (this.clientes.length == 0) {
      this.fetchClientes();
    }
  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
